import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'
import React, {useState} from 'react'
import './LandHeader.css'
import { Link } from 'react-router-dom';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../../assets/images/logo.png';
import whitelogo from '../../assets/images/white_logo.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TranslateIcon from '@mui/icons-material/Translate';
import Menu from '../../components/submenu/Menu';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const menuData = [
  {
  'name':'Home',
  'route':'/',
  'divider': true
},
{
  'name':'News',
  'route':'/',
  'divider': true
},
{
  'name':'About',
  'route':'/',
  'divider': true
},
{
  'name':'Contact',
  'route':'/',
  'divider': false
}
]

const LandHeader = ({setLight, light}) => {

  const [subshow, setSubshow] = useState(false);
  const [newsmenu, setNewsmenu] = useState(false);

  const handleLang = () =>{
    setSubshow(!subshow);
  }

  const handleNews = (val) => {
    if(val === 'News'){
    setNewsmenu(!newsmenu);
    }
  }

  return (
    <div style={{position:'relative', zIndex: 11}}>
    <Box sx={{bgcolor:'primary.main', borderBottom:'1px solid', borderColor:'primary.light'}}>
    <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1, py: 2 }}>
      <Grid container spacing={2} sx={{alignItems:'center', display:{md:'flex', xs:'none'}}}>
        <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-start', alignItems:'center', gap: 2}}>
        <Box sx={{width:'40px', height:'40px', bgcolor:'primary.dark', borderRadius: '50%', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}>
            <LocationOnIcon sx={{color:'text.main'}}/>
          </Box>
          <Typography color='text.main' variant='p' sx={{cursor:'pointer'}}>Tiruvannamalai, Tamilnadu, India</Typography>
        </Grid>
        <Grid item xs={4} sx={{textAlign:'center'}}>
             { light ? <img src={logo} alt='logo' width={150}></img> : <img src={whitelogo} alt='logo' width={150}></img>}
        </Grid>
        <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', alignItems:'center', gap: 2}}>
            <Box sx={{width:'40px', height:'40px', bgcolor:'primary.dark', borderRadius: '50%', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}>
            <SearchIcon sx={{color:'text.main'}}/>
            </Box>
            <Box onClick={handleLang} sx={{width:'40px', height:'40px', bgcolor:'primary.dark', borderRadius: '50%', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer', position:'relative'}}>
            <TranslateIcon sx={{color:'text.main'}}/>
            { subshow && <Box sx={{position:'absolute', bgcolor:'primary.dark', top: '50px', left: '50%', transform:'translateX(-50%)', borderRadius: 2, p: 2}}>
          <Link style={{textDecoration:'none'}}>
                            <Box sx={{ p: 1 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ color: "text.main" }}>
                                  English
                                </Typography>
                              </div>
                            </Box>
                          </Link>
                          <Link style={{textDecoration:'none'}}>
                            <Box sx={{ p:1 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography sx={{ color: "text.main" }}>
                                  Tamil
                                </Typography>
                              </div>
                            </Box>
                          </Link>
          </Box>}
            </Box>
            <Box sx={{width:'40px', height:'40px', bgcolor:'primary.dark', borderRadius: '50%', display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}} onClick={()=> setLight(!light)}>
            {light ? <DarkModeIcon sx={{color:'text.main'}}/>:<LightModeIcon sx={{color:'text.main'}}/>}
            </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{alignItems:'center', display:{md:'none', xs:'flex'}}}>
      <Grid item xs={4} sx={{textAlign:'center'}}>
              <Typography color='text.main' variant='h4' sx={{fontWeight:'medium'}}>NEWSIT</Typography>
        </Grid>
        <Grid item xs={4} sx={{textAlign:'center'}}>
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Button color='text' sx={{fontSize: '14px', p: 0, minWidth: 0}} onClick={()=> setLight(!light)}>{light ? <DarkModeIcon sx={{mr: 1}}/>:<LightModeIcon sx={{mr: 1}}/>}</Button>
            <Button color='text' sx={{fontSize: '14px', p:0, minWidth: 0}}><SearchIcon/></Button>
            </Box>
        </Grid>
        <Grid item xs={4} sx={{textAlign: 'end'}}>
            <Link to='/register'>
            <Button color='secondary' variant='contained'>Subscribe</Button>
            </Link>
        </Grid>
      </Grid>
    </Box>
    </Container>
    </Box>
    <Box sx={{bgcolor:'primary.main', borderBottom:'1px solid', borderColor:'primary.light'}}>
    <Container maxWidth="lg">
            <Box className="menuList" sx={{py: 1, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            {menuData.map((va, index)=>{
              return(
                <>
                <Link className={va.name === 'News' && 'newsNav'} to={va.route} style={{position:'relative'}}>
                <Button onClick={() => handleNews(va.name)} color='text' sx={{fontSize: '14px', textTransform:'uppercase', letterSpacing: 1}}>{va.name}{va.name === 'News' && (newsmenu ? <KeyboardArrowUpIcon sx={{ml: 0.5, color: 'text.main', textAlign:'end', }} /> : <KeyboardArrowDownIcon sx={{ml: 0.5, color: 'text.main', textAlign:'end', }} />)
                  }</Button>
                {va.name === 'News' && newsmenu &&
                <div className='newsMenu'>
                <Menu/>
                </div>
                }
                </Link>
                {va.divider && <Divider sx={{bgcolor:'secondary.main'}} variant='middle' orientation="vertical" flexItem />}
                </>
              )
            })}
    </Box>
    </Container>
    </Box>
    </div>
  )
}

export default LandHeader