import React, { useState, useEffect } from "react";
import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import "./style.css";
import {Custcard, Detailcard} from "../../components/card/Custcard";

const Detail = ({ setLoader }) => {
  return (
    <Box sx={{ bgcolor: "primary.main" }}>
      <Container maxWidth="xl">
        <Grid container spacing={{ xs: 2, md: 0 }} sx={{py: 4, justifyContent:'center'}}>
        <Grid item xs={12} md={6}>
          <Detailcard
          img="https://tripoli.aspirethemes.com/content/images/size/w1304/format/webp/2022/04/history-in-hd-cTz5-T7voqQ-unsplash.jpg"
          topic="POLITICS"
          head="Barack Obama Accompanied by Members of Congress"
          content="President Barack Obama, accompanied by members of Congress and middle school children, waves as he talks on the phone from the Roosevelt Room of the White House to astronauts on the International Space Station."
          author="BEN PARKER and AMELIA HARRY"
          publishdate="April 25, 2022"
          subscri="Public"
        />
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{py: 4}}>
            <Grid item xs={12}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Typography
           color="text.main"
              variant="h4"
              component="div"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontWeight: "bold",
                m: 0
              }}
          >Related
          </Typography>
            </Box>
          <Divider sx={{bgcolor:'primary.light', my: 2}} /> 
            </Grid>
            {Array.from(Array(4)).map((_, index) => (
    <Grid item xs={12} md={3} key={index}>
    <Custcard
          img="https://tripoli.aspirethemes.com/content/images/size/w1304/format/webp/2022/04/history-in-hd-cTz5-T7voqQ-unsplash.jpg"
          topic="POLITICS"
          head="President Donald Trump and VP Mike Pence"
          content="President Donald J. Trump and Vice President Mike Pence."
          author="Amelia Harry"
          publishdate="April 25, 2022"
          subscri="Paid"
        />
    </Grid>
  ))}
        </Grid>
        <Grid container spacing={3} sx={{py: 4}}>
            <Grid item xs={12}>
            <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Typography
           color="text.main"
              variant="h4"
              component="div"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontWeight: "bold",
                m: 0
              }}
          >Latest
          </Typography>
            </Box>
          <Divider sx={{bgcolor:'primary.light', my: 2}} /> 
            </Grid>
            {Array.from(Array(4)).map((_, index) => (
    <Grid item xs={12} md={3} key={index}>
    <Custcard
          img="https://tripoli.aspirethemes.com/content/images/size/w1304/format/webp/2022/04/history-in-hd-cTz5-T7voqQ-unsplash.jpg"
          topic="Covid"
          head="President Donald Trump and VP Mike Pence"
          content="President Donald J. Trump and Vice President Mike Pence."
          author="Amelia Harry"
          publishdate="April 25, 2022"
          subscri="Paid"
        />
    </Grid>
  ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Detail;
