import React, {useState} from 'react';
import Dashboard from './pages/dashboard/Dashboard.js';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Layout2 from './Layout2.js';
import Login from './pages/login/Login.js';
import Home from './pages/home/Home.js';
import Register from './pages/register/Register.js';
import School from './pages/schools/School.js';
import Detail from './pages/details/Detail.js';


const RouteRoute = ({setLoader, setLight, light}) => {

  const [show, setShow] = useState(true);

  return (
    <>
    <Routes>
        <Route path='/' element={<Layout2 light={light} setLight={setLight}/>}>
        <Route path='/' exact element={<Home setLoader={setLoader}/>}></Route> 
        <Route path='/detail' exact element={<Detail setLoader={setLoader}/>}></Route> 
        </Route>
        {/* <Route path='/login' element={<Login setLoader={setLoader}/>}></Route> 
        <Route path='/register' element={<Register setLoader={setLoader}/>}></Route> 
        <Route path='/' element={<Layout show={show} setShow={setShow}/>}>
        <Route path='/dashboard' element={<Dashboard  show={show} setLoader={setLoader}/>}></Route>
        <Route path='/schools' element={<School show={show} setLoader={setLoader}/>}></Route>
        <Route path='/subcategory' element={<Subcategory setLoader={setLoader}/>}></Route>
      </Route> */}
    </Routes>
    </>
  )
}

export default RouteRoute;