import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from '../../assets/images/logo.png';
import whitelogo from '../../assets/images/white_logo.png';

const LandFooter = ({light}) => {
  return (
    <>
        <Box sx={{bgcolor:'primary.dark', py: 5}}>
    <Container maxWidth='xl'>
        <Grid container spacing={2} sx={{justifyContent:'center'}}>
  <Grid item xs={12} md={6}>
      <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      {light ? <img src={logo} alt='logo' width={200}></img> : <img src={whitelogo} alt='logo' width={200}></img>}
      </Box>
      <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, mt: 3, flexWrap:'wrap'}}>
      {Array.from(Array(9)).map((_, index) => (
       <Box key={index} sx={{width:'50px', height:'50px', bgcolor:'primary.main', borderRadius: '50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <InstagramIcon sx={{color:'text.light'}}/>
       </Box>
      ))}
       </Box>
       <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, mt: 3}}>
            <Button color='text' sx={{fontSize: '16px'}}>Home</Button>
            <Button color='text' sx={{fontSize: '16px'}}>About</Button>
            <Button color='text' sx={{fontSize: '16px'}}>Careers</Button>
       </Box>
       <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, mt: 3}}>
            <Typography color='text.light'>&copy; 2024 Newsit – All Rights Reserved </Typography>
       </Box>
  </Grid>
</Grid>

    </Container>
    </Box>
    </>
  )
}

export default LandFooter;