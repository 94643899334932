import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import InstagramIcon from '@mui/icons-material/Instagram';

export function Custcard({
  img,
  topic,
  head,
  content,
  author,
  publishdate,
  subscri,
}) {
  return (
    <Card sx={{ maxWidth: "100%", boxShadow: "none", bgcolor: "transparent" }}>
    <Link to='/detail'>
    <CardMedia sx={{ height: 250 }} image={img} title={topic} />
    </Link>
      <CardContent sx={{ p: 0, pt: 3 }}>
      <Link style={{ textDecoration: "none" }}>
        <Typography
          color="secondary.main"
          variant="p"
          component="div"
          sx={{
            fontWeight: "medium",
            textTransform: "uppercase",
            fontSize: "15px",
            letterSpacing: 2,
            '&:hover' :{
                textDecoration:'underline'
              },
          }}
        >
          {topic}
        </Typography>
        </Link>
        <Link to='/detail' style={{ textDecoration: "none" }}>
          <Typography
            color="text.main"
            variant="h5"
            component="div"
            sx={{
              fontFamily: '"Source Serif 4", serif',
              fontSize: "28px",
              fontWeight: "medium",
              '&:hover' :{
                textDecoration:'underline'
              },
            }}
          >
            {head}
          </Typography>
        </Link>
        {content && (
          <Typography
            variant="body1"
            color="text.main"
            sx={{
              fontFamily: '"Source Serif 4", serif',
              fontSize: "20px",
              py: 0.5,
            }}
          >
            {content}
          </Typography>
        )}
        <Link style={{ textDecoration: "none" }}>
        <Typography
          color="text.light"
          variant="p"
          component="div"
          sx={{
            fontWeight: "medium",
            textTransform: "uppercase",
            fontSize: "14px",
            py: 1,
            '&:hover' :{
                textDecoration:'underline'
              },
          }}
        >
          {author}
        </Typography>
        </Link>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography
            color="text.light"
            variant="p"
            component="div"
            sx={{ fontWeight: "medium", fontSize: "14px" }}
          >
            {publishdate}
          </Typography>
          <Typography
            color="text.light"
            variant="p"
            component="div"
            sx={{
              fontWeight: "medium",
              fontSize: "12px",
              letterSpacing: 1,
              textTransform: "uppercase",
              bgcolor: "primary.dark",
              p: 0.5,
              border: "1px solid",
              borderColor: "primary.light",
            }}
          >
            {subscri}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export function Custcard1({
    img,
    topic,
    head,
    content,
    author,
    publishdate,
    subscri,
  }) {
    return (
      <Card sx={{ maxWidth: "100%", boxShadow: "none", bgcolor: "transparent" }}>
        <CardMedia sx={{ height:{md: 450, xs: 280}}} image={img} title={topic} />
        <CardContent sx={{ p: 0, pt: 3, textAlign:'center' }}>
        <Link style={{ textDecoration: "none" }}>
        <Typography
          color="secondary.main"
          variant="p"
          component="div"
          sx={{
            fontWeight: "medium",
            textTransform: "uppercase",
            fontSize: "15px",
            letterSpacing: 2,
            '&:hover' :{
                textDecoration:'underline'
              },
          }}
        >
          {topic}
        </Typography>
        </Link>
          <Link style={{ textDecoration: "none" }}>
            <Typography
              color="text.main"
              variant="h5"
              component="div"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontSize: "44px",
                fontWeight: "medium",
                '&:hover' :{
                  textDecoration:'underline'
                },
              }}
            >
              {head}
            </Typography>
          </Link>
          {content && (
            <Typography
              variant="body1"
              color="text.main"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontSize: "25px",
                py: 0.5,
              }}
            >
              {content}
            </Typography>
          )}
          <Link style={{ textDecoration: "none" }}>
          <Typography
            color="text.light"
            variant="p"
            component="div"
            sx={{
              fontWeight: "medium",
              textTransform: "uppercase",
              fontSize: "14px",
              py: 1,
              '&:hover' :{
                  textDecoration:'underline'
                },
            }}
          >
            {author}
          </Typography>
          </Link>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent:'center' }}>
            <Typography
              color="text.light"
              variant="p"
              component="div"
              sx={{ fontWeight: "medium", fontSize: "14px" }}
            >
              {publishdate}
            </Typography>
            <Typography
              color="text.light"
              variant="p"
              component="div"
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                letterSpacing: 1,
                textTransform: "uppercase",
                bgcolor: "primary.dark",
                p: 0.5,
                border: "1px solid",
                borderColor: "primary.light",
              }}
            >
              {subscri}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  export function Custcard3({
    img,
    topic,
    head,
    content,
    author,
    publishdate,
    subscri,
  }) {
    return (
      <Card sx={{ maxWidth: "100%", boxShadow: "none", bgcolor: "transparent", display:'flex', justifyContent:'center', alignItems:'center', gap: 1 }}>
        <CardContent sx={{ p: 0}}>
        <Link style={{ textDecoration: "none" }}>
        <Typography
          color="secondary.main"
          variant="p"
          component="div"
          sx={{
            fontWeight: "medium",
            textTransform: "uppercase",
            fontSize: "15px",
            letterSpacing: 2,
            '&:hover' :{
                textDecoration:'underline'
              },
          }}
        >
          {topic}
        </Typography>
        </Link>
          <Link style={{ textDecoration: "none" }}>
            <Typography
              color="text.main"
              variant="h5"
              component="div"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontSize: "18px",
                fontWeight: "medium",
                pt: 1,
                '&:hover' :{
                  textDecoration:'underline'
                },
              }}
            >
              {head}
            </Typography>
          </Link>
          {content && (
            <Typography
              variant="body1"
              color="text.main"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontSize: "20px",
                py: 0.5,
              }}
            >
              {content}
            </Typography>
          )}
          <Link style={{ textDecoration: "none" }}>
          <Typography
            color="text.light"
            variant="p"
            component="div"
            sx={{
              fontWeight: "medium",
              textTransform: "uppercase",
              fontSize: "14px",
              py: 0.5,
              '&:hover' :{
                  textDecoration:'underline'
                },
            }}
          >
            {author}
          </Typography>
          </Link>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              color="text.light"
              variant="p"
              component="div"
              sx={{ fontWeight: "medium", fontSize: "14px" }}
            >
              {publishdate}
            </Typography>
            <Typography
              color="text.light"
              variant="p"
              component="div"
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                letterSpacing: 1,
                textTransform: "uppercase",
                bgcolor: "primary.dark",
                p: 0.5,
                border: "1px solid",
                borderColor: "primary.light",
              }}
            >
              {subscri}
            </Typography>
          </Box>
        </CardContent>
        <img src={img} width={100} height={100}></img>
      </Card>
    );
  }

  export function Custcard4({
    img,
    topic,
    head,
    content,
    author,
    publishdate,
    subscri,
    index
  }) {
    return (
      <Card sx={{ maxWidth: "100%", boxShadow: "none", bgcolor: "transparent" }}>
        <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'flex-end', gap: 4}}>
        <Typography color='primary.dark' variant="h1" sx={{bgcolor:'primary.dark', textShadow:'-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff'}}>{index+1}</Typography>
        <img src={img} width='100%' alt="img"></img>
        </Box>
        <CardContent sx={{ p: 0, pt: 3 }}>
        <Link style={{ textDecoration: "none" }}>
        <Typography
          color="secondary.main"
          variant="p"
          component="div"
          sx={{
            fontWeight: "medium",
            textTransform: "uppercase",
            fontSize: "15px",
            letterSpacing: 2,
            '&:hover' :{
                textDecoration:'underline'
              },
          }}
        >
          {topic}
        </Typography>
        </Link>
          <Link style={{ textDecoration: "none" }}>
            <Typography
              color="text.main"
              variant="h5"
              component="div"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontSize: "28px",
                fontWeight: "medium",
                '&:hover' :{
                  textDecoration:'underline'
                },
              }}
            >
              {head}
            </Typography>
          </Link>
          {content && (
            <Typography
              variant="body1"
              color="text.main"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontSize: "20px",
                py: 0.5,
              }}
            >
              {content}
            </Typography>
          )}
          <Link style={{ textDecoration: "none" }}>
          <Typography
            color="text.light"
            variant="p"
            component="div"
            sx={{
              fontWeight: "medium",
              textTransform: "uppercase",
              fontSize: "14px",
              py: 1,
              '&:hover' :{
                  textDecoration:'underline'
                },
            }}
          >
            {author}
          </Typography>
          </Link>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              color="text.light"
              variant="p"
              component="div"
              sx={{ fontWeight: "medium", fontSize: "14px" }}
            >
              {publishdate}
            </Typography>
            <Typography
              color="text.light"
              variant="p"
              component="div"
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                letterSpacing: 1,
                textTransform: "uppercase",
                bgcolor: "primary.dark",
                p: 0.5,
                border: "1px solid",
                borderColor: "primary.light",
              }}
            >
              {subscri}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  export function Detailcard({
    img,
    topic,
    head,
    content,
    author,
    publishdate,
    subscri,
  }) {
    return (
      <Card sx={{ maxWidth: "100%", boxShadow: "none", bgcolor: "transparent" }}>
        <CardContent sx={{ p: 0, pt: 2 }}>
        <Link style={{ textDecoration: "none" }}>
        <Typography
          color="secondary.main"
          variant="p"
          component="div"
          sx={{
            fontWeight: "medium",
            textTransform: "uppercase",
            fontSize: "15px",
            letterSpacing: 2,
            '&:hover' :{
                textDecoration:'underline'
              },
          }}
        >
          {topic}
        </Typography>
        </Link>
            <Typography
              color="text.main"
              variant="h5"
              component="div"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontSize: "44px",
                fontWeight: "medium",
              }}
            >
              {head}
            </Typography>
          {content && (
            <Typography
              variant="body1"
              color="text.main"
              sx={{
                fontFamily: '"Source Serif 4", serif',
                fontSize: "25px",
                py: 0.5,
              }}
            >
              {content}
            </Typography>
          )}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent:'space-between' }}>
          <div>
          <Link style={{ textDecoration: "none" }}>
          <Typography
            color="text.light"
            variant="p"
            component="div"
            sx={{
              fontWeight: "medium",
              textTransform: "uppercase",
              fontSize: "14px",
              py: 1,
              '&:hover' :{
                  textDecoration:'underline'
                },
            }}
          >
            {author}
          </Typography>
          </Link>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent:'start' }}>
            <Typography
              color="text.light"
              variant="p"
              component="div"
              sx={{ fontWeight: "medium", fontSize: "14px" }}
            >
              {publishdate}
            </Typography>
            <Typography
              color="text.light"
              variant="p"
              component="div"
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                letterSpacing: 1,
                textTransform: "uppercase",
                bgcolor: "primary.dark",
                p: 0.5,
                border: "1px solid",
                borderColor: "primary.light",
              }}
            >
              {subscri}
            </Typography>
          </Box>            
          </div>
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap: 3, flexWrap:'wrap'}}>
      {Array.from(Array(4)).map((_, index) => (
       <Box sx={{width:'50px', height:'50px', bgcolor:'primary.dark', borderRadius: '50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <InstagramIcon sx={{color:'text.light'}}/>
       </Box>
      ))}
       </Box>
          </Box>
        </CardContent>
        <Divider sx={{bgcolor:'primary.light', my: 3}} /> 
        <CardMedia sx={{ height:{md: 450, xs: 280}}} image={img} title={topic} />
      </Card>
    );
  }
